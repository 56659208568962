import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse} from 'two-core';

class ProductsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.products ?? '';
  }

  async getProductsDefinitions(forRevisionId?: number): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(config().keys.current_company_id) ?? ' ';
    let url;
    if (forRevisionId) {
      url = `${config(companyId).endpoints.products}/${forRevisionId}`;
    } else {
      url = config(companyId).endpoints.products;
    }
    return this.get(url)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default ProductsService;
