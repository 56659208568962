import React from 'react';
import AppFrame from './components/AppFrame/AppFrame';
import Made2QuoteAppContext from './context/Made2QuoteAppContext';

import {AppContext, AuthService, TwoToast, UsersService} from 'two-app-ui';
import CompaniesService from './services/CompaniesService';
import OrdersService from './services/OrdersService';
import DraftsService from './services/DraftsService';
import ProductsService from './services/ProductsService';
import LocationsService from './services/LocationsService';
import ContactsService from './services/ContactsService';
import {Toast} from 'primereact/toast';
import {SharedLocationsService} from './services/SharedLocationsService';

const authService = new AuthService();
const companiesService = new CompaniesService(authService);
const ordersService = new OrdersService(authService);
const draftsService = new DraftsService(authService);
const productsService = new ProductsService(authService);
const locationsService = new LocationsService(authService);
const usersService = new UsersService(authService);
const contactsService = new ContactsService(authService);
const sharedLocationsService = new SharedLocationsService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);

function App() {
  return (
    <AppContext.Provider
      value={{
        authService: authService,
        usersService: usersService,
        twoToast: twoToast,
      }}
    >
      <Made2QuoteAppContext.Provider
        value={{
          authService: authService,
          usersService: usersService,
          companiesService: companiesService,
          ordersService: ordersService,
          draftsService: draftsService,
          productsService: productsService,
          twoToast: twoToast,
          locationsService: locationsService,
          contactsService: contactsService,
          sharedLocationsService: sharedLocationsService,
        }}
      >
        <AppFrame />
        <Toast ref={toastRef} />
      </Made2QuoteAppContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
