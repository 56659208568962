import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, PaOrder, QueryParameter} from 'two-core';

class OrdersService extends ApiService {
  constructor(authService: AuthService) {
    super(authService);
  }

  async getOrders(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(config().keys.current_company_id) ?? ' ';
    return this.get(config(companyId).endpoints.orders, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async createOrder(companyId: string, data: PaOrder): Promise<PaOrder> {
    return this.post(`${config(companyId).endpoints.orders}`, data)
      .then(data => {
        return Promise.resolve(data as PaOrder);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}

export default OrdersService;
